.quote__wrapper {
  text-align: center;
  .icon.icon__anker.divider {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  q {
    quotes: "„" "”" "‚" "’";
    font-size: 1.625rem;
    font-weight: 500;
    max-width: 600px;
    display: block;
    margin: 0 auto;
  }
  q::before {
      content: open-quote;
  }
  q::after {
      content: close-quote;
  }
}