.portaltype-easyform {

  label .required::after {
    top: 2px;
  }

  fieldset {
    &:after {
      content: "";
      height: 16px;
      width: 100%;
      background-image: url(/++plone++werkbank.machmeer/images/wave.svg);
      background-position: center;
      display: block;
      background-repeat: no-repeat;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    &:last-of-type:after {
      background: none;
    }
  }
  label {
    font-weight: 300;
    position: relative;
  }
  
  .formControls {
    border-top: none;
    background: transparent;
    padding: 0;
    box-shadow: none;
  }

  input[type="submit"] {
    border-radius: 7px;
    padding: 7px 40px;
    font-weight: 400;
    font-size: 18px;
    margin: 0 auto;
    max-width: max-content;
    display: block;
  }
  
  input[type="file"]::file-selector-button,
  input[type="file"]::-webkit-file-upload-button {
    border-radius: 7px;
    padding: 7px 40px;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    background-color: @primary;
  }

  .radio-widget:after {
      right: -11px;
      position: absolute;
  }

  input[type="text"],
  select,
  textarea { 
    border-radius: 7px;
    border: 1px solid @primary;
    box-shadow: none;
    color: @primary;
  }

  #formfield-form-widgets-plz {
    float: left;
    margin-right: 20px;
  }

  #formfield-form-widgets-stadt {
    float: left;
    margin-right: 20px;
  }

  #formfield-form-widgets-land {
    float: left;
  }

  #formfield-form-widgets-email {
    clear: both;
  }

  input[type="file"]::-webkit-file-upload-button {
    border: none;
    box-shadow: none;
}

}

#formfield-form-widgets-bescheinigungInfo {
  li {
    margin-top: 20px;

    ul li {
      margin-top: 10px;
    }
  }
}