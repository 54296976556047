.leaflet-top,
.leaflet-bottom {
  z-index: 0;
}

.tab .geolocation_wrapper {
  //get rid of the tab-padding
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 100px;
}

.leaflet-popup a.leaflet-popup-close-button.leaflet-popup-close-button {
  top: 55px;
  right: 54px;
  font-size: 27px;
}

.leaflet-popup-content {
  &__title {
    margin-top: 0;
  }
  p {
    margin: 5px 0;
  }
  &__link {
    text-align: right;
    margin-top: 12px;

    a {
      color: @primary;
      font-weight: 500;
      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @primary;
      }
    }
  }
  
}