.quiz {
  margin-top: 50px;

  @media  screen and (min-width: 768px) {
    margin-top: 74px;
  }

  &__container {
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    // user-select: none;
    color: #005498;
    border: 1px solid #005498;
    border-radius: 10px;
    transition: all ease 0.2s;
    display: flex;
    flex: 0;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;

    @media  screen and (min-width: 768px) {
      flex-basis: 48.4%;
    }

    &.checked {
      background-color: @primary;
      color: #fff;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

  }

  .question {
    &__wrapper {
      padding-top: 25px;
      margin-bottom: 50px
    }

    &__flexwrapper {
      display: flex;
      width: 100%;
      padding: 37px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      @media  screen and (min-width: 768px) {
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 63px;
        padding-bottom: 50px;
      }
    }

    &__number {
      background: #B1CBE0;
      height: 63px;
      width: 63px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 25px;
    }

    &__question {
      font-size: 23px;
      font-weight: 500;
    }
  }

  button {
    background-color: @primary;
    border-radius: 10px;
    padding: 14px 30px;
    font-size: 20px;
    width: 370px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
}

#content-core .result {
  padding: 37px;
  margin-bottom: 50px;

  @media  screen and (min-width: 768px) {
    padding: 63px 100px 50px 100px;
  }

  &__image {
    width: 74px;
    max-width: inherit;
    margin-bottom: 35px;

    &--job {
      width: auto;
      height: 130px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 50px;
  }

  &__text {
    text-align: left;
    font-size: 16px;
  }

  &__link {
    display: inline-block;
    margin-top: 40px;
    border: none !important;
    text-decoration: underline;
  }
}

#content-core .card.fullwidth.quiz__teaser {
  background-color: #b2cbdf;

  .result__image {
    height: 220px;
    width: auto;
  }
  
  a {
    max-width: fit-content;
    max-width: max-content;
    background-color: @primary;
    color: #fff;
    border-bottom: none !important;
    border-radius: 10px;
    padding: 14px 30px;
    font-size: 20px;
    margin: 0 auto;
    margin-bottom: 40px;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }
}

.template-quiz-view .resultwrapper-header,
.template-quiz-view .resultwrapper-link {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
.template-quiz-view .resultwrapper-link {
  margin-top: 60px;
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 100px;
}
// .quiz__container input:checked ~ .quiz__checkmark:after {
//   display: block;
// }
// .quiz__container:hover input ~ .quiz__checkmark {
//   background-color: #ccc;
// }

// .quiz__container:input:checked ~ .quiz__checkmark {
//   background-color: #2196F3;
// }


// .quiz__container {
//   display: block;
//   position: relative;
//   padding-left: 35px;
//   margin-bottom: 12px;
//   cursor: pointer;
//   font-size: 22px;
//   user-select: none;
//   color: @primary;
//   border: 1px solid @primary;
//   border-radius: 10px;
//   transition: all ease .2s;

//   &.checked {
//     background-color: @primary;
//     color: #fff;
//   }
//   input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//   }

//   .quiz__checkmark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 25px;
//     width: 25px;
//     background-color: #eee;
//     border-radius: 50%;
//   }

//   .quiz__checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
//   }

//   input:checked ~ .quiz__checkmark:after {
//     display: block;
//   }
  
//   .quiz__checkmark:after {
//     top: 9px;
//    left: 9px;
//    width: 8px;
//    height: 8px;
//    border-radius: 50%;
//    background: white;
//   }

//   &:hover input ~ .quiz__checkmark {
//     background-color: #ccc;
//   }

//   &:input:checked ~ .quiz__checkmark {
//     background-color: #2196F3;
//   }
// }

