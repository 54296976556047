//*// BOOTSTRAP GRID //*//

//*// This is exactly BOOTSTRAP GRID, replace it for your system

// Container widths for anon users
// For logged-in, toolbar adjustments are in main.plone.less
.container {
  .container-fixed();
  max-width: 100%;
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md;
  }
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg;
  }
  @media (min-width: 1300px) {
    width: 1200px;
  }
}

// Fluid container
.container-fluid {
  .container-fixed();
}

// Row
.row {
  .clearfix();
  .make-row();
}

// Columns
.make-grid-columns();

// Extra small grid
.make-grid(xs);

// Small grid
@media (min-width: @plone-screen-sm-min) {
  .make-grid(sm);
}

// Medium grid
@media (min-width: @plone-screen-md-min) {
  .make-grid(md);
}

// Large grid
@media (min-width: @plone-screen-lg-min) {
  .make-grid(lg);
}

// toolbar needs to match grid
#edit-zone.plone-toolbar-top .plone-toolbar-container {
  .container-fixed();
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md;
  }
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg;
  }
  margin-top: 0;
  margin-bottom: 0;
  height: 50px;
}
#edit-zone.plone-toolbar-top.compressed .plone-toolbar-container {
  width: auto;
}
