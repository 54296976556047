.error404 {

  &__title {
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    margin-bottom: 40px;
    span {
      display: block;
      font-size: 40px;
      margin-top: -10px;
    }
  }
  
  svg {
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }
  
  &__message {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-top: 30px;
  }
}