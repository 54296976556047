//
// Dropdown menus
// --------------------------------------------------

@zindex-dropdown: 1000;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
@dropdown-bg: @plone-portlet-content-bg;
//** Dropdown menu `border-color`.
@dropdown-border: rgba(0, 0, 0, 0.15);
//** Dropdown menu `border-color` **for IE8**.
@dropdown-fallback-border: @plone-gray-lighter;
//** Divider color for between dropdown items.
@dropdown-divider-bg: @plone-table-border-color;

//** Dropdown link text color.
@dropdown-link-color: @plone-text-color;
//** Hover color for dropdown links.
@dropdown-link-hover-color: darken(@plone-text-color, 5%);
//** Hover background for dropdown links.
@dropdown-link-hover-bg: #f5f5f5;

//** Active dropdown menu item text color.
@dropdown-link-active-color: @plone-link-hover-color;
//** Active dropdown menu item background color.
@dropdown-link-active-bg: @plone-component-active-bg;

//** Disabled dropdown menu item background color.
@dropdown-link-disabled-color: @plone-gray-light;

//** Text color for headers within dropdown menus.
@dropdown-header-color: @plone-gray-light;

// Reset filters for IE
//
// When you need to remove a gradient background, do not forget to use this to reset
// the IE filter for IE9 and below.

.reset-filter() {
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
}

// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

.nav-divider(@color: #e5e5e5) {
  height: 1px;
  margin: ((@plone-line-height-computed / 2) - 1) 0;
  overflow: hidden;
  background-color: @color;
}

// Dropdown arrow/caret
.caret {
  display: inline-block;
  width: 23px;
  height: 17px;
  // margin-left: 2px;
  background-size: contain;
  border: none;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 28 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;'%3E%3Cg id='ArtBoard1' transform='matrix(0.958854,0,0,1,-17.1788,-24.0935)'%3E%3Crect x='17.916' y='24.094' width='28.772' height='17.74' style='fill:none;'/%3E%3Cg transform='matrix(1.04291,0,0,1,-1.0482,0.0935292)'%3E%3Cpath d='M20,26L31.994,40L44,26' style='fill:none;fill-rule:nonzero;stroke:rgb(0,84,152);stroke-width:4px;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  position: absolute;
  right: 70px;
  top: 45px;
  background-repeat: no-repeat;
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
  position: relative;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  outline: 0;
}

// The dropdown menu (ul)
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: @zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  // float: left;
  min-width: 80%;
  padding: 5px 0;
  margin: 2px 0 0; // override default ul
  list-style: none;
  font-size: @plone-font-size-base;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: @dropdown-bg;
  border: 1px solid @dropdown-fallback-border; // IE8 fallback
  border: 1px solid @dropdown-border;
  border-radius: @plone-border-radius-base;
  .box-shadow(0 6px 12px rgba(0,0,0,0.175));
  background-clip: padding-box;

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    .nav-divider(@dropdown-divider-bg);
  }

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: @plone-font-weight-medium;
    line-height: @plone-line-height-base;
    color: @dropdown-link-color;
    border-bottom: none !important;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
  }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    color: @dropdown-link-hover-color;
    background-color: @dropdown-link-hover-bg;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: @dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: @dropdown-link-active-bg;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    color: @dropdown-link-disabled-color;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    .reset-filter();
    cursor: @cursor-disabled;
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  left: auto; // Reset the default from `.dropdown-menu`
  right: 0;
}
// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: @plone-font-size-small;
  line-height: @plone-line-height-base;
  color: @dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: (@zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    border-top: 0;
    border-bottom: @plone-caret-width-base dashed;
    border-bottom: @plone-caret-width-base solid ~"\9"; // IE8
    content: "";
  }
  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
}

// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.

.dropdown {
  @media screen and (min-width: 992px) {
    width: 420px;
    margin: 0 auto;
  }

  button {
    border: 1px solid #fff;
    background-color: #fff;
    color: @primary;
    border-radius: 6px;
    padding: 12px 22px;
    margin-top: 20px;
    width: 100%;
    font-size: 25px;
  }
}

@media (min-width: @plone-grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu {
      .dropdown-menu-right();
    }
    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      .dropdown-menu-left();
    }
  }
}

.dropdownwrapper {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 446.6 322.1' style='enable-background:new 0 0 446.6 322.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%234B87B6;%7D .st1%7Bfill:%23005397;%7D%0A%3C/style%3E%3Cg%3E%3Crect x='16.3' y='15.6' class='st0' width='414' height='290.6'/%3E%3Cpolygon class='st1' points='198.4,270.2 16.3,306.5 16.3,15.6 430.3,15.6 '/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  margin-left: -33px;
  margin-right: -33px;
  text-align: center;
  padding: 30px 50px;
  min-height: 355px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    color: #fff;
    padding-bottom: 22px;
  }

  @media screen and (min-width: 768px) {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1231 322.1' style='enable-background:new 0 0 1231 322.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%234B87B6;%7D .st1%7Bfill:%23005397;%7D%0A%3C/style%3E%3Cg%3E%3Crect x='12.6' y='9.3' class='st0' width='1200' height='306.7'/%3E%3Cpolygon class='st1' points='730.6,265.2 12.6,316.3 12.6,9.3 1212.6,9.3 '/%3E%3C/g%3E%3C/svg%3E%0A");
    margin-left: -23px;
    margin-right: -27px;
  }
  @media screen and (min-width: 812px) {
    margin-left: -29px;
    margin-right: -15px;
  }
  @media screen and (min-width: 992px) {
    margin-left: -25px;
    margin-right: -30px;
  }
  @media screen and (min-width: 1024px) {
    margin-left: -29px;
    margin-right: -15px;
  }
  @media screen and (min-width: 1200px) {
    margin-left: -199px;
    margin-right: -186px;
  }
}
