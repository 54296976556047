#content-core .school {
  &__logo {
    margin-top: 70px;
    margin-bottom: 30px;
    text-align: center;

    img {
      max-width: 180px;

      @media (min-width: @plone-grid-float-breakpoint) {
        max-width: 100%;
      }
    }
  }

  &__block {
    font-size: 23px;
    margin-bottom: 33px;
    padding-bottom: 20px;
    border-bottom: 3px solid @primary;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 40px;
    }
    
    &--title {
      font-weight: 500;
      margin-bottom: 8px;
    }

    &--content {
      margin-top: -5px;
    }
    a.school__block--content {
      color: @primary !important;
      border-bottom: 1px solid @primary !important;
    }
  }

  .btn.standalone {
    display: block;
    margin: 0 auto;
    font-size: 23px;
    padding-left: 40px;
    padding-right: 40px;
    max-width: fit-content;
    border-radius: 10px;
  }
}
