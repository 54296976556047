.accordion {
  width: 100%;

  .wrapper {
    background: #ffffff;
    box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.5);
    margin-bottom: 40px;
    margin-top: 40px;
    border-radius: 5px;
  }

  ul {
    list-style-type: none;
  }

  .link {
    padding: 15px 30px 5px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease;
    background-color: @primary;
    color: #fff;
  }
  .open .link {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 7px 18px -8px rgba(112, 144, 176, 1);
  }

  .submenu {
    display: none;
    padding: 15px 30px;
  }
}
