#portal-header .ansprechpartner {
  font-size: 1rem;
  display: none;
  padding: 10px;
  width: 100%;
  position: absolute;
  top: 57px;
  background: #fff;
  overflow-y: scroll;
  padding-bottom: 50px;
  height: 100vh;
  border-top: 1px solid @primary;
  
  @media  screen and (max-width: 767px) {
    width: 100vw;
    margin-left: -15px;  
  }

  hr {
    border: 1px solid @primary;
  }

  a {
    color: @primary;
    text-decoration: underline;
  }
     
  @media screen and (min-width: 768px)  {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: 1200px)  {
    padding-left: 80px;
    padding-right: 80px;
  }
  
  &__mainheader {
    font-size: 2.125rem;
    margin-top: 20px;
    display: inline-block;
    font-weight: 500;
  }

  &__wrapper {
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      max-width: 50%;
    }
  }
  
  .icon.icon__shipwave {
    margin-top: 21px;
    margin-bottom: 50px;
  }

  &__header {
    // color: @primarylight;
    font-size: 1.4rem;
    font-weight: 500;
  }
  &__name,
  &__organisation {
    font-weight: 500;
    margin-bottom: 0;
  }
  
  &__organisation {
    font-size: 1.4rem;
    margin-bottom: 16px;
    font-weight: 400;
  }

  .icon__wave {
    max-width: 103px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}