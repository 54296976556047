@font-face {
  font-family: "RedHatText";
  font-style: normal;
  font-weight: 400;
  src: url("redhattext/redhat-regular.eot");
  src: local(""),
  url("redhattext/redhat-regular.eot?#iefix") format("embedded-opentype"),
  url("redhattext/redhat-regular.woff2") format("woff2"),
  url("redhattext/redhat-regular.woff") format("woff"),
  url("redhattext/redhat-regular.ttf") format("truetype"),
  url("redhattext/redhat-regular.svg#RedHatText") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "RedHatText";
  font-style: normal;
  font-weight: 500;
  src: url("redhattext/redhat-500.eot");
  src: local(""),
  url("redhattext/redhat-500.eot?#iefix") format("embedded-opentype"),
  url("redhattext/redhat-500.woff2") format("woff2"),
  url("redhattext/redhat-500.woff") format("woff"),
  url("redhattext/redhat-500.ttf") format("truetype"),
  url("redhattext/redhat-500.svg#RedHatText") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "RedHatText";
  font-style: normal;
  font-weight: 700;
  src: url("redhattext/redhat-700.eot");
  src: local(""),
    url("redhattext/redhat-700.eot?#iefix") format("embedded-opentype"),
    url("redhattext/redhat-700.woff2") format("woff2"),
    url("redhattext/redhat-700.woff") format("woff"),
    url("redhattext/redhat-700.ttf") format("truetype"),
    url("redhattext/redhat-700.svg#RedHatText") format("svg");
  font-display: swap;
}
