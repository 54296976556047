.ships {
  &__wrapper {
    background: #ffffff;
    box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.5);
    margin-bottom: 40px;
    border-radius: 5px;
  }

  &__header {
    color: @primary;
    margin: 0;
    font-size: 23px;
    font-weight: 300;
  }
  
  &__content {
    padding: 15px 30px;
  }

  .accordion {
    .link {
      background: #fff;
      padding-bottom: 20px;
      padding-top: 19px;

      .caret.white {
        height: 50px;
        width: 50px;
        top: 8px;

        svg path {
          stroke: @primary;
        }
      }
    }

    .submenu {
      padding: 0;

      figure {
        img {
          object-fit: cover;
          width: 100%;
        }

        figcaption {
          padding: 15px 30px;
          padding-top: 5px;
          font-size: 80%;
        }
      }
    }

    .open .link {
      box-shadow: none;
    }
  }

}