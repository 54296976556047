#content {
  .splide__arrows {
    button.splide__arrow--prev {
      left: -60px;
      background: transparent;
      opacity: 1;
      width: 50px;
      height: 50px;
      @media screen and (max-width: 768px) {
          left: 0px;
          width: 30px;
          height: 30px;
          }
      @media screen and (max-width: 1200px) {
          left: 0px;
          width: 45px;
          height: 45px;
          }
      svg {
        fill: @primary;
        width: 100%;
        height: 100%;
      }
    }

    button.splide__arrow--next {
      right: -60px;
      opacity: 1;
      width: 50px;
      height: 50px;
      background: transparent;
      @media screen and (max-width: 768px) {
          right: 0px;
          width: 30px;
          height: 30px;
          }
      @media screen and (max-width: 1200px) {
          right: 0px;
          width: 45px;
          height: 45px;
          }
      svg {
        fill: @primary;
        width: 100%;
        height: 100%;
      }
    }
  }
  // Slider 
  .splide__pagination {
    bottom: -1.5em;
  }

  .splide__pagination__page {
    border: 1px solid #005498;
    background: transparent;
    width: 10px;
    height: 10px;
    transition: all ease .3s;
    
    &.is-active {
      background: @primary;
      opacity: 1;
      // transform: scale(1);
      transform: none;
    }
  }
}
// .slider .grid-container {
//   background: #fff;
// }

// .slider {
//   @supports(display: grid) {

//     .grid-container {
//       display: grid;
//       //grid-template-columns: 1fr;
//   //    grid-template-rows:  1fr;
//       grid-template-areas: "main";

//       @media screen and (max-width: 500px) {
//         grid-template-columns: 1fr;
//         grid-template-rows: 1fr;
//         grid-template-areas: "main";
//       }
//     }

//     .grid-item {
//       color: #fff;
//       padding: 3.5em 1em;
//       font-size: 1em;
//       font-weight: 700;
//     }
    
    

//     .main {
//       color: @primary;
//       background-color:#fff;
//       grid-area: main;
//       padding: 0;
//       overflow-x: scroll;
//       overflow-y: hidden;
//     }
    

//     .items {
//       position: relative;
//       width: 100%;
//       overflow-x: scroll;
//       overflow-y: hidden;
//       white-space: nowrap;
//       transition: all 0.2s;
//       transform: scale(0.98);
//       will-change: transform;
//       user-select: none;
//       cursor: pointer;
//     }

//     .items.active {
//       background: rgba(255,255,255,0.3);
//       cursor: grabbing;
//       cursor: -webkit-grabbing;
//       transform: scale(1);
//     }

//     .item {
//       display: inline-block;
//       min-height: 250px;
//       min-width: 400px;
//       margin: 2em 1em;
//       @media screen and (max-width: 500px) {
//         min-height: 200px;
//         min-width: 200px;
//       }
//     }
//   }
// }
