/* our custom styles */

/* TinyMCE templates */
.tiny__wave-divider,
.tiny__ship-divider {
  &--wrapper {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  &--image {
    background-image: url(/++plone++werkbank.machmeer/images/wave.svg);
    background-repeat: no-repeat;
    height: 14px;
    width: 108px;
  }
}

.tiny__ship-divider {
  &--image {
    background-image: url(/++plone++werkbank.machmeer/images/ship.svg);
    height: 90px;
    width: 106px;
  }
}

.userrole-anonymous {
  #portal-anontools {
    display: none;
  }
}

.image-richtext.image-responsive {
  max-width: 100%;
}


#content-header {
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 2;
  transition: all 0.2s ease 0s;
  top: 0;

  .container {
    @media screen and (min-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.medium {
  font-weight: 500;
}

.scroll-down {
  #content-header {
    top: -80px;
  }
}

header {
  // margin-bottom: 60px;
  .icon__shipwave {
    margin-top: 50px;
  }
}

// height of navigation because of fixed header
.headerspacer {
  height: 80px;
  transition: height ease 0.2s;
}

.scroll-down .headerspacer {
  height: 0;
}
// icon after description/h1
.icon__shipwave.divider {
  margin-top: 65px;
  margin-bottom: 46px;
}

.mainwrapper {
  margin-top: 72px;
  box-shadow: 0 56px 40px 5px rgba(112, 144, 176, 0.5);
}

#main-container,
#portal-footer {
  padding-top: 50px;
  @media screen and (min-width: 1200px) {
    padding: 0px 170px;
    padding-top: 50px;
  }
}

.template-edit #main-container {
  padding-bottom: 200px;
}

.leadImage img {
  width: 100%;
  object-fit: cover;
  height: 370px;

  @media screen and (min-width: 768px) {
    height: initial;
  }
}

.container.nopadding {
  padding-left: 0;
  padding-right: 0;
}

.container.sliderfix {
  overflow: hidden;
}
.silderwrapper {
  margin-right: 16px;
  overflow: hidden;
}

#content-core .slider {
  scroll-snap-type: x mandatory;
  font-size: 1rem;
  position: relative;
  white-space: nowrap;
  scroll-behavior: smooth;
  scrollbar-width: none;
  --offset: 2em;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-y: hidden;
  width: 100vw;
  left: calc(var(--offset) * -1);
  padding: 0 var(--offset);

  @media screen and (min-width: 1200px) {
    max-width: 800px;
    --offset: 0;
  }
  @media screen and (min-width: 1300px) {
    max-width: 831px;
    --offset: 0;
  }
  a {
    text-decoration: none;
    display: inline-block;
    color: black;
    margin-right: 10px;
    border-bottom: none;
    @media screen and (min-width: 1200px) {
      margin-right: 42px;
    }
    .slide {
      // padding: 2em;
      border-radius: 10px;
      transition: transform 300ms ease-in 0s;
      height: 246px;
      width: 249px;
      scroll-snap-align: start;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
      // background: rgb(183, 202, 67) none repeat scroll 0% 0%;
    }
  }
}

#content-core .einleser {
  font-size: 23px;
}

.sliderlink {
  border-radius: 110px;
  border: none !important;
  height: 70px;
  width: 70px;
  justify-content: center;
  display: flex;
  align-content: center;
  position: relative;
  background-color: @primary;
  left: calc(100% - 90px);
  top: -20px;
  svg {
    width: 48px;
  }
}

.plone-toolbar-top {
  #content-header {
    margin-top: 50px;
  }
  .mainwrapper {
    margin-top: 122px;
  }
}

#content-core .events {
  margin-top: 30px;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
  .event {
    display: flex;
    flex-basis: 48%;
    flex-direction: column;
    box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.5);
    border-radius: 5px;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;

    &__date {
      font-weight: 500;
      font-size: 34px;
    }

    &__title {
      font-size: 23px;
      font-weight: 500;
    }

    &__text {
      font-size: 16px;
    }
    &__link {
      text-align: right;
      border-bottom: none;
    }
  }
}

.icon.divider {
  display: block;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 80px;
}

.standalone {
  transition: all ease 0.2s;
  text-decoration: none;
  border-bottom: none !important;
}

// .dropdown .lupe {
//   background-color: @primary;
//   background-image: url(/++plone++werkbank.machmeer/images/lupe-weiss.svg);
//   background-repeat: no-repeat;
//   height: 57px;
//   position: absolute;
//   width: 50px;
//   right: 2px;
//   top: 22px;
//   border-radius: 5px;
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   background-size: 33px;
//   background-position: 10px;
// }
.dropdownwrapper {
  select {
    height: 57px;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    @media screen and (min-width: 768px) {
      width: 350px;
    }
  }
  form {
    display: flex;
    justify-content: center;
  }
  .dropdown-toggle {
    // height: 57px;
    // left: -4px;
    width: 53px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    // top: -1px;
    margin-top: 0;
    padding: 0;
    position: relative;

    &:hover {
      background-color: #fff !important;
    }

    .lupe {
      background-color: @primary;
      background-image: url(/++plone++werkbank.machmeer/images/lupe-weiss.svg);
      background-repeat: no-repeat;
      // height: 57px;
      height: 55px;
      position: absolute;
      width: 51px;
      // right: 2px;
      // top: 22px;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-size: 33px;
      background-position: 10px;
      top: 0;
    }
  }
}
.jobs.einleser {
  font-size: 23px;
  margin-bottom: 50px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;

  &__tag {
    padding: 1px 25px;
    margin: 5px 0 0 0;
    border-radius: 40px;
    border: 1px solid @primary;
    font-style: italic;
    font-size: 16px;
    margin-right: 20px;
  }
}

// responsive Videos 16/9
.videowrapper {
  //padding-bottom: calc(var(--aspect-ratio, .5625) * 100%);
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// @padding: 96px;
// @videowidth: 800px; // wildcard settings
// @media screen and (max-width: 767px){
//   #splide-track {
//     padding-right: @padding;
//   }
//   .splide__slide.is-active.is-visible {
//     width: calc(@videowidth - @padding);
//   }
//   .splide__slide {
//     margin-right: 20px;
//   }
// }
// center html videos
.mejs-container {
  margin: 0 auto;

  video {
    max-width: 100%;
  }
}

// Vinegar
#vinegar-bottle {
  position: fixed;

  .vinegar-title,
  a {
    color: @primary;
  }
  #vinegar-short .vinegar-buttons .vinegar-button#vinegar-accept button {
    background-color: @primary;
  }
  #vinegar-short .vinegar-buttons .vinegar-button#vinegar-show_more button {
    border-color: @primary;
  }
}

//TinyMCE Accordion
.content-accordion {
  margin-bottom: 25px;
  margin-top: 25px;

  &__head {
    background-color: @primary;
    color: #fff;
    border-radius: 6px;
    padding: 17px 15px;
    padding-right: 40px;
    font-size: 23px;
    transition: all ease 0.2s;
    position: relative;
    cursor: pointer;

    &:after {
      display: inline-block;
      content: "";
      height: 50px;
      width: 50px;
      position: absolute;
      right: 0;
      background-repeat: no-repeat;
      top: 11px;
      transition: all ease 0.2s;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ENavigation Down%3C/title%3E%3Cdesc%3EA solid styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' d='M21 22a2.99 2.99 0 0 1 2.121.879l8.89 8.636 8.868-8.636a3 3 0 0 1 4.242 4.242L32.011 40 18.879 27.121A3 3 0 0 1 21 22z' fill='%23ffffff'%3E%3C/path%3E%3C/svg%3E");
    }

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    display: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #ffffff;
    padding: 28px 15px;
    box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.5);
  }
}
#tinymce .content-accordion__content {
  display: block !important;
}

// Absätze in Artikeln
.spacer:empty {
  height: 15px;
}
// Kleine Bilder auf Jobprofil-Seite

.jobprofile_container_small_img {
  display: flex;
  justify-content: space-between;
  .jobprofile_small_img {
    img {
      object-fit: cover;
      border-radius: 5px;
      margin-top: 80px;
    }
  }
  .jobprofile_small_img:nth-child(1) {
    padding-right: 15px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-between;

    .jobprofile_small_img:nth-child(2) {
      img {
        margin-top: 0px;
      }
    }

    .jobprofile_small_img:nth-child(1) {
      padding-right: 0px;
      padding-bottom: 15px;
    }
  }
}

// jobprofil icons
.jobicon {
  height: 100px;
  background-position: center;
  margin-top: 80px;
  margin-bottom: 80px;
  background-repeat: no-repeat;
  background-image: url(/++plone++werkbank.machmeer/images/icon__kaufleute.svg);

  &__schiffsmechaniker {
    background-image: url(/++plone++werkbank.machmeer/images/icon__mechanik.svg);
  }

  &__elektrotechnik {
    background-image: url(/++plone++werkbank.machmeer/images/icon__elektro.svg);
  }

  &__nautik {
    background-image: url(/++plone++werkbank.machmeer/images/icon__nautik.svg);
  }

  &__technik {
    background-image: url(/++plone++werkbank.machmeer/images/icon__technik.svg);
  }
}

.template-ambassador-view .documentDescription {
  font-weight: 400;
}


// hide contact icon in header when on contact page
.section-kontakt .plone-navbar-header .contact {
  display: none;
}
.literatur {
  border-bottom: 2px solid;
  margin-top: 40px;
  padding-bottom: 30px;
  
  &:last-child {
    border-bottom: none;
  }

  &__title {
    font-weight: 500;
    margin-bottom: 20px;
  }
}


.index_helper {
  z-index: 1;
  position: relative;
}

// Fix for broken plone 
.template-folder_contents #content-core {
  min-height: 750px;
  font-size: 80%;
  
  .actionmenu-container {
    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      position: inherit;
    }
  }
}

#popover-delete #content-core {
  min-height: 5px;
}

#vinegar-bottle > div {
  max-width: 600px !important;
}

#vinegar-bottle .vinegar-buttons button {
  border-radius: 10px !important;
}

#vinegar-bottle #vinegar-short .vinegar-buttons .vinegar-button#vinegar-show_more button {
  color: @primary !important;
  background-color: #fff !important;
  border: 2px solid @primary !important;
}

#vinegar-bottle #vinegar-short .vinegar-text {
  max-width: 100% !important;
}

// Stellenangebote
.nounderline {
  text-decoration: none;
  border-bottom: none !important;
}

// Stoerer
.stoerer {
  background: #b2cbdf; /* fallback */
  background: url("img/ballons.png") no-repeat 10px 10px, linear-gradient(to top, #b2cbdf 0%, #ffffff 85%);
  padding: 65px 10px 20px 100px;
  margin-bottom: 50px;
}

// Versuch, das BBS-Logo einzubauen
#bbs-logo {
  font-weight: 500;
}

#bbs-h2 {
  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
}

#portal-logo:hover {
    text-decoration: none;
    color: #005498;
}

//#portal-header {
//  background: url("img/bbs.png") no-repeat;
//  background-position: right;
//}

.plone-navbar-header {
  padding-top: 15px;
}
