.cardwrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;

  &.jobs {
    flex-direction: column;
    align-items: center;

    .subtitle {
      margin-bottom: 20px;
      align-self: flex-start;
    }
  }
}

.card {
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 16px 40px 5px rgba(112, 144, 176, 0.5);
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 344px;
  }

  @media screen and (min-width: 992px) {
    max-width: 450px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 374px;
  }

  img {
    // object-fit: cover;
    width: 100%;
    border-radius: 5px 5px 0 0;
  }

  #myimage img {
    width: auto;
  }

  &__title {
    font-size: 23px;
    margin-top: 19px;
    margin-bottom: 20px;
    padding: 0 10px;
  }

  &.fullwidth {
    flex-basis: 100%;
    max-width: initial;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  &__job {
    text-align: left;
    padding: 15px;
    margin-bottom: 45px;
    // box-shadow: 0 0px 20px 0 rgb(112 144 176 / 50%);
    box-shadow: 0 0px 20px 0 rgba(112, 144, 176, 0.5);
    font-size: 23px;
    max-width: 100%;

    .wrapper {
      display: flex;
      margin-top: 60px;
      align-items: flex-end;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;

        .wrapper_inner {
          padding-bottom: 15px;
        }
      }
    }

    p,
    h2 {
      margin-bottom: 0;
    }

    .title {
      font-size: 23px;
      font-weight: 500;
      margin-top: 0;

      span {
        font-weight: 500;
        // display: block;
      }
    }

    .link {
      width: 45px;
      height: 45px;
      margin-bottom: -8px;
      display: block;
      border-bottom: none !important;
      color: @primary;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("/++plone++werkbank.machmeer/images/arrow.svg");

      @media screen and (max-width: 767px) {
        align-self: flex-end;
      }
    }
  }

  &__events {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__event {
    text-align: left;
    margin-bottom: 75px;
    max-width: 100%;

    &--title {
      font-size: 23px;
    }

    &__header {
      img {
        width: 100%;
      }
    }

    &__body {
      padding: 15px;
    }

    &__footer {
      margin-top: 40px;

      @media screen and (min-width: 768px) {
        display: flex;
      }

      .item {
        font-weight: 500;
        display: flex;
        margin-bottom: 10px;

        @media screen and (min-width: 768px) {
          flex: 0 0 33%;

          &:nth-child(2) {
            justify-content: center;
          }
          &:nth-child(3) {
            justify-content: flex-end;
          }
        }

        svg {
          height: 20px;
          margin-right: 5px;
        }
      }

      a {
        border-bottom: none !important;
        color: @primary;
      }
    }
  }
}

#content-core .cardwrapper.azubinfo {
  flex-direction: column;
  
  @media screen and (min-width: 768px){ 
    flex-direction: row;
  }

  .card {
    padding: 32px;
    flex: 0 0 100%;
    margin-bottom: 50px;
    
    img {
      height: 90px;
      margin-bottom: 40px;
    }

    @media screen and (min-width: 768px){
      flex: 0 0 31%;  
    }
    
  }
}

.cardwrapper.bloggerlist {
  flex-wrap: wrap;
  @media screen and (min-width: 768px){
    &::after {
      content: '';
      flex: 0 0 30%;
    }
  }
  .card {
    flex: 1 1 100%;
    margin-bottom: 100px;
    @media screen and (min-width: 768px){
      flex: 0 0 30%;
      margin-bottom: 40px;
      img {
        height: 200px !important;
      }
    }

    &__image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 300px;
      background-size: cover;
      background-image: url(/++plone++werkbank.machmeer/images/blogger__meissner.jpg);
      @media screen and (min-width: 768px){
        height: 200px;
      }
    }

    &__textwrapper {
      padding: 32px 16px;
    }

    &__name {
      font-size: 23px;
      font-weight: 500;
    }

    &__bereich {
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
}
