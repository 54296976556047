//*// FOOTER //*//

#portal-footer-wrapper {
  padding: 30px 0 30px 0;
  background-color: #b2cbdf;
}

#portal-footer {
  a {
    color: @primary;
    text-decoration: underline;
  }
  .doormat {
    &.row {
      margin-left: 0;
      margin-right: 0;
    }

    > div:nth-child(1),
    > div:nth-child(4),
    > div:nth-child(5) {
      width: 100%;
    }
    > div:nth-child(2),
    > div:nth-child(3) {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: 50%;
      }
    }
    > div:nth-child(4) img {
      max-height: 80px;
    }
    // .col-xs-12.col-md-3:first-of-type {
    //   width: 100%;
    // }
    // .col-xs-12.col-md-3:last-of-type {
    //   width: 100%;
    // }
  }
  .socialicons a{
    text-decoration: none;
  }

  .impressum {
    font-size: 16px;
    margin-top: 60px;
  }
}

#subfooter.container {
  padding: 0px 30px;
  @media screen and (min-width: 768px) {
    padding: 0px 170px;
  }
  
  padding-bottom: 50px;
  
  img {
    margin-right: 30px;
    margin-bottom: 60px;
    max-width: 100%;
  }
}