.template-blog-eintrag-view {
  .documentFirstHeading {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .author {
    font-size: 23px;
    margin-bottom: 40px;
    display: inline-block;

    a {
      color: @primary;
      text-decoration: underline;
    }
  }
}

#content .blogentries {
  &__author {
    font-size: 22px;
    margin-bottom: 40px;

    a {
      color: @primary;
      border-bottom: 2px solid @primary;
      &:hover {
        text-decoration: none;
      }
    }
  }

  h2 {
    margin-bottom: 0;
  }

  &__teasertext {
    font-size: 23px;
  }

  &__button {
    background-color: @primary;
    border-radius: 10px;
    padding: 14px 30px;
    font-size: 20px;
    width: 370px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
