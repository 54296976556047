.content-tabs {
  display: flex;
  flex-wrap: wrap; // make sure it wraps
  justify-content: space-between;
  font-size: 23px;

  label {
    // min-width: 220px;
    // width: 45%;
    width: 30%;
    text-align: center;
    order: 1; // Put the labels first
    display: block;
    padding: 10px 0;
    border: 2px solid @primary;
    cursor: pointer;
    font-weight: 400;
    border-radius: 50px;
    transition: background ease 0.2s;
  }

  .tab {
    order: 99; // Put the tabs last
    flex-grow: 1;
    width: 100%;
    opacity: 0;
    height: 0;
    z-index: 0;
  }

  input[type="radio"] {
    display: none;
    

    &:checked + label {
      background: @primary;
      color: #fff;
    }
    &:checked + label + .tab {
      display: block;
      opacity: 1;
      margin-top: 66px;
      margin-bottom: 50px;
      padding: 1rem;
      height: auto;
    }
  }

  @media (max-width: 45em) {
    //uncomment for normal tab behavior
    // .tab,
    // .label {
    //    order: initial;
    // }
    label {
      width: 100%;
      margin-right: 0;
      margin-top: 0.2rem;
      max-width: inherit;
    }
  }

  .faketab_link {
    display: block;
    width: 30%;
    text-align: center;
    order: 3;
    border: 2px solid #005498 !important;
    padding: 10px 0;
    border-radius: 50px;
    margin-bottom: 6px;

    &:hover {
      text-decoration: none;
      color: #005498;
    }

    @media (max-width: 45em) { 
      width: 100%;
      margin-right: 0;
      margin-top: 0.2rem;
    }
  }
}

.subsection-schifffahrtskaufleute .content-tabs label {
   width: 45%;
   @media (max-width: 45em) { 
     width: 100%;
   }
}

#content-core .schools-list,
#content-core .reederein-list {
  width: 100%;

  .school_cart,
  .reederei_cart {
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: -1rem;
    margin-left: -1rem;
    border-radius: 5px;
    // box-shadow: 0 0px 10px 0 rgb(112 144 176 / 50%);
    box-shadow: 0 0px 18px 5px rgba(112, 144, 176, .25);
    display: flex;
    flex-direction: column;
    .school_ort,
    .reederein_ort {
      font-size: 16px;
    }
    .school_title,
    .reederein_title {
      font-size: 23px;
      font-weight: 500;
    }
    .school_studiengang,
    .reederein_studiengang {
      font-size: 23px;
    }
    .school_link,
    .reederein__link {
      align-self: flex-end;
      border-bottom: none;
    }
  }
  .reederei_cart a {
    font-size: 18px;
    text-decoration: underline;
  }
}
