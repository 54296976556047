//*// SITE NAV //*//

.plone-nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  @media (min-width: @plone-grid-float-breakpoint) {
    height: calc(100% + 210px);
    // border-right: 1px solid #979797;
    flex: 0 0 50%;
  }

  li {
    position: relative;
    display: block;
    &.has_subtree {
      a {
        padding-right: 2.5em;
        font-size: 24px;
        font-weight: 500;
        @media (min-width: @plone-grid-float-breakpoint) {
          font-size: 34px;
          // font-weight: 400;
        }
      }
      ul li a {
        font-size: 23px;
        padding-left: 8px;
        // font-weight: 400;
        li a {
          font-weight: 400;
        }
      }
    }

    label {
      margin: 0;
    }

    a {
      position: relative;
      display: block;
      padding: @plone-sitenav-link-padding;
      color: @primary;
      font-size: 23px;
      hyphens: auto;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    // Navigation sublevels
    ul {
      padding-left: 0.5em;
// akkordion function
      // @media screen and (max-width: 767px) {
      //   visibility: hidden;
      //   opacity: 0;
      //   height: 0;
      // }
      li {
        a {
          font-size: ceil((@plone-font-size-base * 1.1));
        }
      }
      &.dropdown {
        width: auto;
      }
    }

    .opener {
      display: none;

      & + label:after {
        transform: rotate(0deg);
        transition: ease-in 0.35s;
      }

      &:checked + label:after {
        transform: rotate(-180deg);
        transition: ease-in 0.35s;
      }

      & ~ ul {
        transition: ease-out 0.35s;
      }

      &:checked ~ ul {
        height: auto;
        transition: ease-in 0.35s;
      }

      &:checked ~ ul {
        visibility: visible;
        opacity: 1;
      }
    }
  
    // akkordion function
    // label {
    //   position: absolute;
    //   right: 0em;
    //   top: 0em;
    //   height: 100%;
    //   width: 4em;
    //   text-align: center;
    //   &:after {
    //     content: "▼";
    //     cursor: pointer;
    //     display: inline-block;
    //     line-height: 1;
    //     width: 1.5em;
    //     height: 1.5em;
    //     font-size: 1.25em;
    //     padding: 0.25em;
    //     right: 0.5em;
    //     top: 0.45em;
    //     position: absolute;
    //     @media screen and (min-width: 768px) {
    //       display: none;
    //     }
    //   }
    // }
  }

  // add some space to nav bottom
  > li:last-child {
    @media (min-width: @plone-grid-float-breakpoint) {
      margin-bottom: 100px;
    }
  }
}

.plone-navbar {
  background-color: @plone-sitenav-bg;
  position: relative;
  min-height: @plone-sitenav-height; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
  margin-bottom: @plone-sitenav-margin-bottom;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);

  // Prevent floats from breaking the navbar
  // &:extend(.clearfix all);
}

.plone-navbar-header {
  .contact {
    // &:hover {
    //   text-decoration: none;
    // }
    background-image: url(/++plone++werkbank.machmeer/images/contact.svg);
    height: 22px;
    cursor: pointer;
    width: 26px;
    display: inline-block;
    position: relative;
    top: 7px;
    background-repeat: no-repeat;
    &.active {
      height: 20px;
      top: 5px;
      background-image: url(/++plone++werkbank.machmeer/images/close.svg);
    }
  }
}

.plone-navbar-collapse {
  background-color: @primary;
  overflow-x: visible;
  padding-right: @plone-sitenav-padding-horizontal;
  padding-left: @plone-sitenav-padding-horizontal;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch;
  position: absolute;
  left: -100%;
  transition: all ease 0.2s;
  z-index: 1;

  &.in {
    left: 0;
    width: 100%;
    // background-color: #fff;
  }

  &.plone-collapse {
    display: block !important;
    padding-bottom: 0; // Override default setting
    overflow: visible !important;
  }

  &.in {
    overflow-y: visible;
  }
  // Undo the collapse side padding for navbars with containers to ensure
  // alignment of right-aligned contents.
  .navbar-fixed-top &,
  .navbar-static-top &,
  .navbar-fixed-bottom & {
    padding-left: 0;
    padding-right: 0;
  }

  //enables scroll in fixed nav
  .scrollcontainer {
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 260px;
    @media (min-width: @plone-grid-float-breakpoint) {
      display: flex;
      padding-bottom: inherit;
      border-top: 1px solid @primary;
      padding-top: 50px;
    }
  }
  // }
}

.plone-navbar-toggle {
  z-index: 1; //just in case
  position: relative;
  // margin-top: 8px;
  // margin-bottom: 6px;
  padding: 6px 10px;
  background: none;
  border: none;

  &:focus {
    outline: 0;
    background: none;
  }

  &:hover {
    background: none;
    border-color: none;
    box-shadow: none;
  }
  &:active {
    background: none;
  }

  // Burgerbar (uncomment for a ≡ menu and comment menu just below)

  .icon-bar {
    display: block;
    width: 28px;
    height: 3px;
    border-radius: 2px;
    background: @primary;
    margin-top: 2px;
    transition: all ease 0.2s;

    transform: rotate(45deg);
    position: relative;
    top: 8px;
  }

  .icon-bar + .icon-bar {
    opacity: 0;
  }

  .icon-bar + .icon-bar + .icon-bar {
    margin-bottom: 3px;
    transform: rotate(-45deg);
    top: -3px;
    opacity: 1;
    position: relative;
  }

  &.collapsed {
    .icon-bar {
      display: block;
      width: 28px;
      height: 3px;
      border-radius: 2px;
      background: @primary;
      margin-top: 2px;
      transform: none;
      top: 0;
    }

    .icon-bar + .icon-bar {
      margin-top: 5px;
      opacity: 1;
    }

    .icon-bar + .icon-bar + .icon-bar {
      margin-bottom: 3px;
      transform: none;
      opacity: 1;
      top: 0;
    }
  }
}

.plone-navbar-nav {
  margin: 0 -@plone-sitenav-padding-horizontal 0;
  background: #fff;
  transition: top ease 0.2s;

  > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: @plone-line-height-computed;
  }
  // use selected for the last item in the structure

  .current > a,
  .selected > a {
    &,
    &:hover,
    &:focus {
      color: white;
    }
  }


  // Uncollapse the nav
  @media (min-width: @plone-grid-float-breakpoint) {
    > li {
      &.has_subtree a {
        padding-right: 2em;
      }

      > a {
        padding-top: @plone-sitenav-padding-vertical;
        padding-bottom: @plone-sitenav-padding-vertical;
      }

      label {
        font-size: 1em;
        background: transparent;
        &:after {
        }
      }

      li.has_subtree label:after {
        transform: rotate(-90deg);
      }
    }

    &.navbar-right:last-child {
      margin-right: -@plone-sitenav-padding-horizontal;
    }

    .has_subtree.inPath > a {
      &,
      &:hover,
      &:focus {
        color: white;
      }
    }
  }
}

#portal-globalnav-collapse {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 72px;
  background-color: #fff;
  padding-bottom: 50px;

  @media screen and (max-width: 767px) {
    padding: 0;
    border-top: 1px solid @primary;
  }
}

.scroll-down {
  #portal-globalnav-collapse {
    top: 0;
  }
}

.scroll-up {
  #portal-globalnav-collapse {
    top: 72px;
  }
}

ul.plone-navbar-nav {
  li ul li ul {
    // display:none;
  }
  @media screen and (max-width: 767px) {
    li {
      label:after {
        color: #fff;
      }
      a {
        background-color: @primary;
        color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 40px;
      }
    }
    li ul li {
      label:after {
        color: @primary;
      }
      a {
        background-color: #fff;
        color: @primary;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 0;
      }
    }
  }
  li.has_subtree {
    a {
      pointer-events: none;
    }

    ul li {
      a {
        pointer-events: initial;
      }
    }
  }
  li.has_subtree.blogs a {
    pointer-events: initial;
  }
}
.menue_infos {
  cursor: default;
}

//mobile search
.searchboxwrapper {
  @media (min-width: @plone-grid-float-breakpoint) {
    flex: 0 0 50%;
    padding-left: 40px;
  }
}

#portal-searchbox {
  margin-left: 3px;
  margin-top: 60px;
  padding-top: 20px;
  position: relative;
  white-space: nowrap;
  // z-index: 2;
  display: inline-block;

  .searchSection {
    display: none;
  } //non on mobile

  [type="text"] {
    width: 20rem;
    color: @primary;
    font-size: 23px;
    padding-left: 33px;
    padding-top: 25px;
    padding-bottom: 23px;
    border-radius: 40px;
    border: 2px solid @primary;
    &::placeholder {
      color: @primary;
    }
  }

  [type="submit"] {
    // display: none;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0px;
    width: 60px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border: 0;
    background: transparent;
    cursor: pointer;
    z-index: 1;
    &:hover {
      box-shadow: none;
    }
  } //submit hidden on mobile

  label {
    font-size: 86.667%;
  }

  .LSBox {
    position: relative;
    display: inline-block;
    label {
      position: absolute;
    }
  }

  .icon__search {
    width: 48px;
    height: auto;
    position: absolute;
    top: 0;
    right: 10px;

    path,
    circle {
      stroke: @primary;
      stroke-width: 4;
    }
  }

  &.mobileOnly {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &.desktopOnly {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }
}

ul.static-nav.plone-nav {
  display: block;
  flex: none;
  height: auto;
  border: none;
  margin-top: -9px;
  // padding-left: 30px;
  > li:last-child {
    margin-bottom: 0;
  }

  li {
    border: none;
  }
  @media screen and (max-width: 767px) { 
    margin-left: -16px;
    // margin-top: 35px;

    li {
      margin-left: 1px;
      ul {
        padding-left: 0;
      }
    }
    a {
      padding-left: 17px !important;
    }
    a.noclick {
      background: @primary;
      color: #fff;
      margin-bottom: 20px;
      margin-top: 50px;
      width: calc(100% + 15px);
    }
  }
}

ul.plone-nav li ul li ul.has_subtree {
  padding-left: 25px;

  li a {
    font-weight: 400;
  }
}

.plone-nav li.index_html {
  display: none;
  a {
    font-weight: 500;
  }
}

@media (min-width: @plone-grid-float-breakpoint) {
  .plone-nav li.has_subtree {
    padding-top: 40px;
    border-right: 1px solid #979797;
    // &:first-of-type {
    //   margin-top: 0;
    // }
    ul li {
      padding-top: inherit;
      border-right: none
    }
  }
}

.noclick {
  cursor: default;
  pointer-events: none;
}