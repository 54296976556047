.download {
  margin-top: 44px;
  font-size: 16px;
  padding-bottom: 40px;
  border-bottom: 2px solid @primary;

  &:last-of-type {
    border-bottom: none;
  }

  .icon {
    height: 56px;
    width: 44px;
  }

  .pdf {
    background-image: url("/++plone++werkbank.machmeer/images/pdf.svg");
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .XLSX,
  .xlsx,
  .xls {
    background-image: url("/++plone++werkbank.machmeer/images/xlsx.svg");
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .doc,
  .docx {
    background-image: url("/++plone++werkbank.machmeer/images/docx.svg");
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .standalone {
    border-radius: 7px;
    padding: 7px 40px;
    font-weight: 400;
    font-size: 18px;
    margin: 0 auto;
    max-width: max-content;
    display: block;
  }
}

.caret.white {
  right: 17px;
  top: 21px;
  transition: all ease 0.3s;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 28 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;'%3E%3Cg id='ArtBoard1' transform='matrix(0.958854,0,0,1,-17.1788,-24.0935)'%3E%3Crect x='17.916' y='24.094' width='28.772' height='17.74' style='fill:none;'/%3E%3Cg transform='matrix(1.04291,0,0,1,-1.0482,0.0935292)'%3E%3Cpath d='M20,26L31.994,40L44,26' style='fill:none;fill-rule:nonzero;stroke:rgb(255,255,255);stroke-width:4px;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");

  .open & {
    top: 18px;
    transform: rotate(180deg);
  }
}


.template-download-view {
  .content-accordion {
    &__head {
      font-size: 18px;
      &:after {
        width: 23px;
        height: 17px;
        top: 24px;
        right: 14px;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 28 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;'%3E%3Cg id='ArtBoard1' transform='matrix(0.958854,0,0,1,-17.1788,-24.0935)'%3E%3Crect x='17.916' y='24.094' width='28.772' height='17.74' style='fill:none;'/%3E%3Cg transform='matrix(1.04291,0,0,1,-1.0482,0.0935292)'%3E%3Cpath d='M20,26L31.994,40L44,26' style='fill:none;fill-rule:nonzero;stroke:rgb(255,255,255);stroke-width:4px;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
      &.active:after {
        top: 22px;
      }
    }
  }
}