.template-startpage_view {
  // .icon__shipwave,
  // .icon__wave {
  //   margin: 0 auto;
  //   display: block;
  // }
  // .icon__wave {
  //   margin-bottom: 40px;
  // }
  .cardwrapper {
    flex-direction: column;
    @media screen and (min-width: @plone-screen-sm-min) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .card {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
      @media screen and (min-width: 992px) {
        // flex: 0 0 48%;
        margin-bottom: 60px;
        &:last-of-type {
          margin-bottom: 60px;
        }
        @media screen and (min-width: 1300px) { 
          margin-bottom: 70px;
          &:last-of-type {
            margin-bottom: 70px;
          }
        }
      }
    }
  }

  .dropdownwrapper {
    .dropdown {
      display: flex;
      justify-content: center;
    }
    .dropdown-menu {
      left: inherit;
    }
  }
  // .blogteaser {
  //   display: flex;
  //   justify-content: space-between;
  //   position: relative;
  //   margin-bottom: 50px;

  //   a {
  //     display: block;
  //     flex: 0 0 30%;
  //     img {
  //       object-fit: cover;
  //     }
  //   }

  // }

  // .icon__anker {
  //   margin: 0 auto;
  //   display: block;
  //   margin-top: 100px;
  //   margin-bottom: 80px;
  // }
}
