#content-core .linkcollection {
  margin-bottom: 40px;
  margin-top: 40px;
  border-bottom: 2px solid @primary;

  &__link {
    color: @primary;
    border-bottom: none;
    font-size: 23px;
    font-weight: 500;

    &::before {
      content: "\e806";
      font-family: "Fontello";
      font-size: 100%;
      padding: 0;
      margin: 0;
      position: relative;
      left: inherit;
      display: inline-block;
      color: inherit;
      width: 20px;
      height: 20px;
      text-align: center;
      margin-right: 6px;
    }
  }

  &__description {
    padding-left: 27px;
  }
}
