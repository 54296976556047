//*// HEADER //*//
#portal-header {
  margin: 15px auto 15px;
  display: flex;
  justify-content: space-between;
  position: relative;

}

#portal-logo {
  margin-top: 5px;
}

#portal-languageselector {
  clear: both;
  display: inline-block;
  margin-right: 29px;
  padding-top: 5px;
  li {
    display: inline-block;
    margin-right: 10px;
  }
}
#portal-anontools {
  ul {
    padding-right: 0;
  }
}
#portal-membertools-wrapper {
  padding: @plone-padding-base-horizontal 0;
  ul {
    margin: 0 0 @plone-padding-base-horizontal @plone-padding-base-horizontal;
    padding-right: 0;
  }
}

//non mobile search
// @media (min-width: @plone-grid-float-breakpoint) {
//   #portal-header {
//     margin-bottom: 15px;
//   }
//   #portal-languageselector {
//   }
//   #portal-searchbox {
//     margin-left: 1em;
//     margin-top: 5px; //visual balance
//     max-width: 50%;
//     padding-top: 0;
//     position: relative;
//     .searchSection {
//       display: inherit;
//     } //yes for non mobile
//     [type="text"] {
//       border-bottom-right-radius: 0;
//       border-top-right-radius: 0;
//       width: 20rem;
//       color: @primary;
//       &::placeholder {
//         color: @primary;
//       }
//     }
//     [type="submit"] {
//       display: inline-block;
//       margin-left: -1px;
//       vertical-align: top;
//       width: auto;
//       display: inline-block;
//     }
//   }
// }
