.searchPage{
  position: relative;
  
  .actionMenu.activated{
    dd{
      display: block;
    }
  }
  .actionMenu{
    float: right;

    dt{
      top: 0;
      right: 0;
      position: absolute;
    }

    dd{
      display: none;
      position: absolute;
      right: 0;
      top: 35px;
      width: 300px;
      background-color:#fcfcfd;
      border: 1px solid #e5e5e5;
      padding: 15px;
      z-index:10;
    }
  }

  .autotabs {
    border: none;
    box-shadow: none;
    
    #search-results {
      font-size: 23px;
      
      li {
        margin-bottom: 36px;
      }

      a {
        color: @primary;
        border-bottom: none !important;
      }

      .result-title,
      ::marker {
        font-weight: 500;
      }
    }
  }
}

#search-results-bar{
  #results-count{
    float: right;
    padding-top: 15px;
    padding-right: 15px;
  }
}

#searchform{
  .input-group{
    // max-width: 600px;
    position: relative;
    margin-bottom: 80px;

    input.searchPage.form-control {
      font-size: 23px;
      font-weight: 500;
      color: @primary;
      border: 2px solid @primary;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
      padding: 25px;
      z-index: 0;

      &:focus {
        box-shadow: none;
      }
    }

    input.searchPage.btn{
      padding: 15px 15px 14px 15px;
      border: none;
      width: 80px;
      background: url(/++plone++werkbank.machmeer/images/lupe-weiss.svg) @primary no-repeat;
      background-size: 33px;
      background-position: 15px center;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      
    }
  }
}

#search-filter input {
  margin-right: 0.5rem;
}

#content-core #search-results .pagination {
  li > a {
    border: none !important;
  }
  
  li.active > span {
    background-color: @primary;
    border: none !important; 
  }
}

// livesearch
.pat-livesearch .livesearch-results {
  box-shadow: none;
  border: 1px solid #005498;
  padding: 18px;
  margin-top: 10px;
  border-radius: 8px;
  @media screen and (max-width: 767px) {
    max-width: 100% !important;
    overflow: scroll;
    height: 61vh;
  }
}